import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import * as _ from 'underscore';

import { ChartConfiguration, ChartData, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';

import { faPrint, faPieChart } from '@fortawesome/free-solid-svg-icons';
import { faUncharted } from '@fortawesome/free-brands-svg-icons';

import { SessionStorageService } from '../../../core/services/session-storage.service';
import { ManagementUser } from '../../../core/models/management-user.model';
import { District } from '../../../core/models/district.model';
import { School } from '../../../core/models/school.model';
import { SchoolClass } from '../../../core/models/school-class.model';
import { SubscriptionTypes } from '../../../core/models/subscription-types.model';
import { Student } from '../../../core/models/student.model';
import { ReportsService } from '../../../core/services/reports.service';
import { StudentsFilterComponent } from '../../../shared/components/students-filter/students-filter.component';
import { StudentsService } from '../../../core/services/students.service';
import { AssessmentScore } from '../../../core/models/assessment-score.model';
import { ScoreCutoffs } from '../../../core/models/score-cutoffs.model';
import { PrintService } from 'src/app/core/services/print.service';

@Component({
  selector: 'wf-diagnostic-report',
  templateUrl: './diagnostic.component.html',
  styleUrls: ['./diagnostic.component.css']
})
export class DiagnosticReportComponent implements OnInit, AfterViewInit {
  currentUser: ManagementUser | null = null ;
  isSystem: boolean = false ;
  isElementary: boolean = false ;
  showArchived: boolean = false ;
  showSchoolFilter: boolean = false ;
  schoolFilterError: string = '' ;
  productRoute: string = '' ;
  automaticityTitle: string = 'Word Recognition' ;
  tierLabels: { [key: string]: string } = {} ;
  districts: District[] = [] ;
  schools: School[] = [] ;
  teachers: ManagementUser[] = [] ;
  grades: string[] = [] ;
  classes: SchoolClass[] = [] ;
  testWindows: string[] = [] ;
  testWindowsCompare: string[] = [] ;
  students: Student[] = [] ;
  filteredStudents: Student[] = [] ;
  scoreTotalsWindow1: any = {} ;
  scoreTotalsWindow2: any = {} ;
  automaticityData: number[] = [ 0, 0, 0 ] ;
  decodingData: number[] = [ 0, 0, 0 ] ;
  selectedProduct: string = '' ;
  sortColumn: string = '' ;
  sortReverse: boolean = false ;
  compareTestWindows: boolean = false ;
  selectedTestWindow: string = '' ;
  selectedCompareTestWindow: string = '' ;
  navigateStudentId: number = -1 ;
  chartType: ChartType = 'pie' ;
  chartData: ChartData<'pie', number[], string | string[]> = {
    labels: [ 'Proficient', 'Some Risk', 'High Risk' ],
    datasets: [{
      data: [ this.automaticityData[0], this.automaticityData[1], this.automaticityData[2] ],
      backgroundColor: [
        '#58B957',
        '#F2AE43',
        '#DB524B'
      ],
      hoverBackgroundColor: [
        '#58B957',
        '#F2AE43',
        '#DB524B'
      ],
    }]
  } ;
  chartOptions: ChartConfiguration['options'] = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
      tooltip: {
        displayColors: false,
        callbacks: {
          label: (toolTip) => {
            //let totalStudents: number = toolTip.dataset['data'].reduce((prev: number, curr: number) => { return prev! + curr! }, 0) ;
            let totalStudents = 0 ;
            toolTip.dataset['data'].forEach((val: any) => totalStudents += (val as number)) ;

            return (totalStudents === 0) ? '' : `${Math.round((toolTip.parsed / totalStudents) * 100)}%` ;
          }
        }
      }
    },
  } ;
  printIcon = faPrint ;
  diagnosticIcon = faUncharted ;

  @ViewChild(BaseChartDirective) chart?: BaseChartDirective ;
  @ViewChild(StudentsFilterComponent) studentFilter?: StudentsFilterComponent ;
  @ViewChild('printContent') printContent!: ElementRef<HTMLElement>;
  @ViewChild('automaticityPieCanvas') automaticityPieCanvas!: ElementRef<HTMLCanvasElement>;
  @ViewChild('automaticitySummaryImg') automaticitySummaryImg!: ElementRef<HTMLImageElement>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private reportsService: ReportsService,
    private studentsService: StudentsService,
    private sessionStorageService: SessionStorageService,
    private changeDetector: ChangeDetectorRef,
    private printService: PrintService,
  ) {
    this.navigateStudentId = parseInt(this.router.getCurrentNavigation()?.extras?.state?.['studentId']) ;
    if (isNaN(this.navigateStudentId)) this.navigateStudentId = -1 ;
  }

  ngOnInit(): void {
    this.currentUser = this.sessionStorageService.getUserData() ;
    this.showSchoolFilter = this.currentUser!.isSchoolUser() || this.currentUser!.isFILUser() || this.currentUser!.isDistrictUser() ;

    if (this.route.snapshot.data['resolveData'].erred)
    {
      this.schoolFilterError = this.route.snapshot.data['resolveData'].message ;
    }
    else
    {
      this.students = this.route.snapshot.data['resolveData'].students ;
      this.grades = this.route.snapshot.data['resolveData'].grades ;
      this.classes = this.route.snapshot.data['resolveData'].classes ;
      this.testWindows = this.route.snapshot.data['resolveData'].testWindows ;
      this.testWindowsCompare = this.route.snapshot.data['resolveData'].compareTestWindows ;
      this.filteredStudents = this.students ;
    }

    // Filter data
    this.districts = this.route.snapshot.data['filterData'].districts ;
    this.schools = this.route.snapshot.data['filterData'].schools ;
    this.teachers = this.route.snapshot.data['filterData'].teachers ;

    if (this.currentUser!.isFILUser() || this.currentUser!.isDistrictUser())
    {
      // The FIL user and District user roles can select all schools
      this.schools.unshift({ schoolID: 0, districtID: 0, name: 'All', enabled: true }) ;
    }

    if (this.currentUser!.isFILUser() || this.currentUser!.isDistrictUser() || this.currentUser!.isSchoolUser())
    {
      // The FIL user and District and School user roles can select all teachers
      this.teachers.unshift(ManagementUser.getGenericUser()) ;
    }

    // Setup our selected product
    // There seems like duplication here, but productRoute is used for routing, and we want to keep this separate from
    // the selectedProduct, which is used in displays and might change for display reasons (rebranding, etc) and should
    // not affect the route URL
    this.isSystem = this.route.snapshot.data['product'] === SubscriptionTypes.FullProduct ;
    this.selectedProduct = this.route.snapshot.data['product']
    this.productRoute = (this.isSystem) ? 'system' : 'diagnostic' ;

    // Prime our selected test windows and archive options
    if (this.sessionStorageService.getDiagnosticOpts() === null)
    {
      // Initialize our diagnostic report options
      let opts: { [key: string] : any } = {} ;
      opts[SubscriptionTypes.FullProduct] = {
        'showArchived': false,
        'showCompare': false,
        'testWindow': null,
        'compareWindow': null,
      } ;
      opts[SubscriptionTypes.DiagnosticProduct] = {
        'showArchived': false,
        'showCompare': false,
        'testWindow': null,
        'compareWindow': null,
      } ;
      this.sessionStorageService.setDiagnosticOpts(opts) ;
    }
    this.showArchived = this.sessionStorageService.getDiagnosticOpts()[this.selectedProduct]['showArchived'] ;

    let compareWindowIdx = (this.testWindows.length > 2) ? 1 : 0 ;
    let window = this.sessionStorageService.getDiagnosticOpts()[this.selectedProduct]['testWindow'] ;
    let compareWindow = this.sessionStorageService.getDiagnosticOpts()[this.selectedProduct]['compareWindow'] ;
    this.selectedTestWindow = (window !== null) ? window : 'All Tests' ;
    this.selectedCompareTestWindow = (compareWindow !== null) ? compareWindow : this.testWindowsCompare[compareWindowIdx] ;

    // Before we set our diagnostic students, we need to make sure and process the scores
    // NOTE: This is a bit wasteful in that we do not need to populate the report data here,
    //     : but this method goes through assessments and tally's and assigns instructional priorities
    this.reportsService.processStudentsAssessmentScores(this.students, {}, { 'testWindow': this.selectedTestWindow }, { 'testWindow': this.selectedCompareTestWindow }) ;
    this.sessionStorageService.setDiagnosticStudents(this.students) ;

    // NOTE: This is a bit of a hack and work around for the fact that to view any students detailed diagnostic data,
    //     : all the data is loaded and put on the session HERE first, in the general diagnostic report. So if we are linking
    //     : directly to see a students detailed diagnostics (from say the Student Summary report), we need to stop here first
    //     : to load and process the data, then forward the visitor on. Ideally this is reworked so that the detailed diagnostic
    //     : report can handle all the data loading, I dont' think as it was originally designed there was thought to viewing it
    //     : stand-alone, as there was no other way to get to the detailed diagnostic except from the general diagnostic
    if (this.navigateStudentId > 0)
    {
      this.filterResults({ grade: 'all', class: 0, extraSupport: 'all' }) ;
      this.goToDiagnosticDetails(this.navigateStudentId, true) ;
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.filterResults(this.studentFilter?.getFilterSettings()) ;
      this.setReportSummaryData(this.filteredStudents) ;
    }) ;
  }

  filterResults(filterOpts: any) {
    // Grade value that comes from the filter is our true, displayed grade (not our internal grade, so 4 is 4th grade)
    let gradeNum = parseInt(filterOpts.grade) ;
    let selectedGradeGroup = (filterOpts.grade === 'four_below_all' || (!isNaN(gradeNum) && gradeNum <= 4)) ? 'elementary' : 'secondary' ;
    if (filterOpts.grade === 'all')
    {
      this.tierLabels = {
        'proficient' : '',
        'some-risk' : '',
        'high-risk' : '',
      } ;
    }
    else
    {
      this.tierLabels = {
        'proficient' : ScoreCutoffs[selectedGradeGroup]['labels']['topTier']['range'],
        'some-risk' : ScoreCutoffs[selectedGradeGroup]['labels']['middleTier']['range'],
        'high-risk' : ScoreCutoffs[selectedGradeGroup]['labels']['bottomTier']['range'],
      } ;
    }
    this.isElementary = (filterOpts.grade === 'four_below_all' || (!isNaN(gradeNum) && gradeNum <= 4)) ;


    let students = this.studentsService.filterStudents(this.sessionStorageService.getDiagnosticStudents(), filterOpts) ;
    this.filteredStudents = [] ;

    // We need to filter our students based on if we are comparing test windows, or if we are just filtering on results
    // (we do not care about the selected test type).
    if (this.compareTestWindows)
    {
      let sameCompareWindows = (this.selectedCompareTestWindow === this.selectedTestWindow) ;
      students.forEach((student) => {
        if (student.enabled || this.showArchived)
        {
          // If we are looking for a specific test type (test window), we need to reset the student assessments
          // and only add the ones that match our filtered test window
          let foundAssessmentScore = false ;
          let assessmentScores: AssessmentScore[] = [] ;

          student.assessmentScores?.forEach((assessment) => {
            if ((assessment.testWindow === this.selectedTestWindow) || (assessment.testWindow === this.selectedCompareTestWindow))
            {
              foundAssessmentScore = true ;
              assessmentScores.push(assessment) ;
            }
          }) ;

          if (assessmentScores.length === 2 || (foundAssessmentScore && sameCompareWindows))
          {
            student.assessmentScores = assessmentScores ;
            this.filteredStudents.push(student) ;
          }
        }
      }) ;
    }
    else
    {
      // Filter results
      if (this.selectedTestWindow === 'All Tests')
      {
        // We are filtering for all tests, so add the student if it is enabled (or if we are showing archived)
        students.forEach((student) => {
          if (student.enabled || this.showArchived) this.filteredStudents.push(student) ;
        }) ;
      }
      else
      {
        students.forEach((student) => {
          if (!student.enabled && !this.showArchived) return ;

          let foundAssessmentScore = false ;
          let assessmentScores: AssessmentScore[] = [] ;

          student.assessmentScores?.forEach((assessment) => {
            if (assessment.testWindow === this.selectedTestWindow)
            {
              foundAssessmentScore = true ;
              assessmentScores.push(assessment) ;
            }
          }) ;

          if (foundAssessmentScore)
          {
            student.assessmentScores = assessmentScores ;
            this.filteredStudents.push(student) ;
          }
        }) ;
      }
    }

    this.setReportSummaryData(this.filteredStudents) ;
    this.sessionStorageService.setDiagnosticDetailsStudents(this.filteredStudents) ;
  }

  filterSchoolTeacher() {
    this.reportsService.getDiagnosticData(this.isSystem).subscribe({
      next: (data: any) => {
        if (data.erred)
        {
          this.schoolFilterError = data.message ;
        }
        else
        {
          this.schoolFilterError = '' ;
          this.students = data.students ;
          this.grades = data.grades ;
          this.classes = data.classes ;
          this.testWindows = data.testWindows ;
          this.testWindowsCompare = data.compareTestWindows ;
          this.filteredStudents = this.students ;
          this.changeDetector.detectChanges();

          let compareWindowIdx = (this.testWindows.length > 2) ? 1 : 0 ;
          let window = this.sessionStorageService.getDiagnosticOpts()[this.selectedProduct]['testWindow'] ;
          let compareWindow = this.sessionStorageService.getDiagnosticOpts()[this.selectedProduct]['compareWindow'] ;
          this.selectedTestWindow = (window !== null) ? window : 'All Tests' ;
          this.selectedCompareTestWindow = (compareWindow !== null) ? compareWindow : this.testWindowsCompare[compareWindowIdx] ;

          // Before we set our diagnostic students, we need to make sure and process the scores
          // NOTE: This is a bit wasteful in that we do not need to populate the report data here,
          //     : but this method goes through assessments and tally's and assigns instructional priorities
          this.reportsService.processStudentsAssessmentScores(this.students, {}, { 'testWindow': this.selectedTestWindow }, { 'testWindow': this.selectedCompareTestWindow }) ;
          this.sessionStorageService.setDiagnosticStudents(this.students) ;

          this.studentFilter?.resetFilterSettings() ;
          this.filterResults(this.studentFilter?.getFilterSettings()) ;
        }
      }
    }) ;
  }

  sortTable(col: string) {
    if (col === 'dateCompleted' && (this.selectedTestWindow === 'All Tests' || this.compareTestWindows)) {
      // Do not sort by date when "All Tests" is selected
      return;
    }
    
    if (this.sortColumn === col) {
      this.sortReverse = !this.sortReverse;
    } else {
      this.sortReverse = false;
      this.sortColumn = col;
    }
  
    this.filteredStudents = (this.sortReverse)
      ? _.sortBy(this.filteredStudents, this.sortFunction, this).reverse()
      : _.sortBy(this.filteredStudents, this.sortFunction, this);
  }

  // Called whenever the test window opts are changed (window/compare/compare window)
  testWindowChanged() {
    let opts = this.sessionStorageService.getDiagnosticOpts() ;
    opts[this.selectedProduct] = {
      'showArchived': this.showArchived,
      'showCompare': this.compareTestWindows,
      'testWindow': this.selectedTestWindow,
      'compareWindow': this.selectedCompareTestWindow,
    }
    this.sessionStorageService.setDiagnosticOpts(opts) ;

    if (this.selectedTestWindow === 'All Tests')
    {
      this.compareTestWindows = false ;
    }

    this.filterResults(this.studentFilter?.getFilterSettings()) ;
  }

  goToDiagnosticDetails(userId: number, skipLocationChange: boolean = false) {
    let studentIdx = this.filteredStudents.findIndex(student => student.userID === userId) ;
    let navExtras = {} ;

    if (studentIdx === -1) return ;

    if (skipLocationChange) {
      navExtras = {
        skipLocationChange: true,
      }
    }

    this.sessionStorageService.setDiagnosticDetailsStudent(this.filteredStudents[studentIdx]) ;
    this.router.navigate([ '/reports/', this.productRoute, 'diagnostic', ((this.filteredStudents[studentIdx].grade <= 5) ? 'elementary' : 'secondary'), 'detail', userId], navExtras) ;
  }

  getDiagnosticClass(isElementary: boolean, score: number) {
    return this.reportsService.getDiagnosticClass((isElementary ? 'elementary' : 'secondary'), score) ;
  }

  getDiagnosticImage(isElementary: boolean, score: number) {
    return this.reportsService.getDiagnosticImage((isElementary ? 'elementary' : 'secondary'), score) ;
  }

  printSummary() {
    let opts = this.sessionStorageService.getDiagnosticOpts() ;
    if (this.selectedTestWindow !== 'All Tests' && !opts[this.selectedProduct].showCompare) {
      this.reportsService.convertCanvasToImage(this.automaticityPieCanvas.nativeElement, this.automaticitySummaryImg.nativeElement);
    }
    let school = this.reportsService.getSelectedSchoolForReports().name;
    let teacher = this.reportsService.getSelectedTeacherForReports();
    let teacherName = `${teacher.firstName} ${teacher.lastName}`;
    this.printService.openPrintWindow(this.printContent.nativeElement, school, teacherName);
  }

  private sortFunction(val: any) {
    if (this.sortColumn === 'systemScore' || this.sortColumn === 'decodingScore' || this.sortColumn === 'zone') {
      return val.assessmentScores[0][this.sortColumn];
    }
    if (this.sortColumn === 'dateCompleted') {
      return new Date(val.assessmentScores[0].dateCompleted);
    }
    return val[this.sortColumn];
  }

  private setReportSummaryData(students: Student[]) {
    let scoreTotals: any = {} ;
    let scoreTotalsWindow1 = {
      'testWindow': this.selectedTestWindow,
    } ;
    let scoreTotalsWindow2 = {
      'testWindow': this.selectedCompareTestWindow,
    } ;

    this.reportsService.processStudentsAssessmentScores(students, scoreTotals, scoreTotalsWindow1, scoreTotalsWindow2) ;
    this.scoreTotalsWindow1 = scoreTotalsWindow1 ;
    this.scoreTotalsWindow2 = scoreTotalsWindow2 ;

    this.automaticityData = [
      scoreTotals.automaticityProficient,
      scoreTotals.automaticitySomeRisk,
      scoreTotals.automaticityHighRisk,
    ] ;
    this.decodingData = [
      scoreTotals.decodingProficient,
      scoreTotals.decodingSomeRisk,
      scoreTotals.decodingHighRisk,
    ] ;

    this.chartData.datasets[0].data = [ this.automaticityData[0], this.automaticityData[1], this.automaticityData[2] ] ;
    this.chart?.update() ;

    // After we have set the report summary data, we need to update our students in the session
    //this.sessionStorageService.setDiagnosticStudents(students) ;
  }
}
