export const environment = {
  production: true,
  dataServicesURL: 'https://wfservices.wordflight.com/',
  assetServerURL: 'https://student.wordflight.com',
  studentSiteURL: 'https://student.wordflight.com',
  SENTRY_DSN: 'https://95f6a6cdcea2b81f37b0704585b64726@o4506633830989824.ingest.us.sentry.io/4507103776997376',
  CLEVER_REDIRECT_URI: 'https://wfservices.wordflight.com/clever/login',
  CLEVER_CLIENT_ID: '6c6d4dfbf1c99e1e747e',
  CLASSLINK_REDIRECT_URI: 'https://wfservices.wordflight.com/classlink/login',              
  CLASSLINK_CLIENT_ID: 'c163216955075175c1d49930a17ebc6f8702f1f5f7a2',
  TYPOGRAPHY_URL: 'https://cloud.typography.com/7034538/7531232/css/fonts.css',
  versionNumber: '5.007',
  hubspotPrivateAppToken: 'pat-na1-01e3b1f6-ca95-4623-8a90-1c7670c32b0b',
  hubspotPortalId: 24309339,
  showAnnouncement: false,
  teacherReviewTimeoutHour: 1,
};
